import Rails from '@rails/ujs';

$( document ).on('turbolinks:load', function() {
  console.log("PC Recon Custom JS Laoded");

  $('form').on('keypress', e => {
    if (e.keyCode == 13) {
        return false;
      }
  });

  function updateValues(){
    // Opening Counts
    if ($("#user-closing-nickels").val() == '') {
      $("#user-closing-nickels").val('0.00')
    };
    if ($("#user-closing-dimes").val() == '') {
      $("#user-closing-dimes").val('0.00')
    };
    if ($("#user-closing-quarters").val() == '') {
      $("#user-closing-quarters").val('0.00')
    };
    if ($("#user-closing-ones").val() == '') {
      $("#user-closing-ones").val('0.00')
    };
    if ($("#user-closing-fives").val() == '') {
      $("#user-closing-fives").val('0.00')
    };
    if ($("#user-closing-tens").val() == '') {
      $("#user-closing-tens").val('0.00')
    };
    if ($("#user-closing-twenties").val() == '') {
      $("#user-closing-twenties").val('0.00')
    };
    if ($("#user-closing-fifties").val() == '') {
      $("#user-closing-fifties").val('0.00')
    };
    if ($("#user-closing-hundreds").val() == '') {
      $("#user-closing-hundreds").val('0.00')
    };
    if ($("#user-closing-drawer").val() == '') {
      $("#user-closing-drawer").val('0.00')
    };

    // Closing Counts
    if ($("#user-opening-nickels").val() == '') {
      $("#user-opening-nickels").val('0.00')
    };
    if ($("#user-opening-dimes").val() == '') {
      $("#user-opening-dimes").val('0.00')
    };
    if ($("#user-opening-quarters").val() == '') {
      $("#user-opening-quarters").val('0.00')
    };
    if ($("#user-opening-ones").val() == '') {
      $("#user-opening-ones").val('0.00')
    };
    if ($("#user-opening-fives").val() == '') {
      $("#user-opening-fives").val('0.00')
    };
    if ($("#user-opening-tens").val() == '') {
      $("#user-opening-tens").val('0.00')
    };
    if ($("#user-opening-twenties").val() == '') {
      $("#user-opening-twenties").val('0.00')
    };
    if ($("#user-opening-fifties").val() == '') {
      $("#user-opening-fifties").val('0.00')
    };
    if ($("#user-opening-hundreds").val() == '') {
      $("#user-opening-hundreds").val('0.00')
    };
    if ($("#user-opening-drawer").val() == '') {
      $("#user-opening-drawer").val('0.00')
    };

    // Other Inputs
    if ($("#user-change-order").val() == '') {
      $("#user-change-order").val('0.00')
    };
    if ($("#user-pos-eod-cash-deposit").val() == '') {
      $("#user-pos-eod-cash-deposit").val('0.00')
    };
    if ($("#user-bank-deposit").val() == '') {
      $("#user-bank-deposit").val('0.00')
    };
    if ($("#user-paid-in").val() == '') {
      $("#user-paid-in").val('0.00')
    };
    if ($("#user-paid-outs").val() == '') {
      $("#user-paid-outs").val('0.00')
    };

    $("#variance-nickels").val((parseFloat($("#user-closing-nickels").val()) - parseFloat($("#user-opening-nickels").val())).toFixed(2));
    $("#variance-dimes").val((parseFloat($("#user-closing-dimes").val()) - parseFloat($("#user-opening-dimes").val())).toFixed(2));
    $("#variance-quarters").val((parseFloat($("#user-closing-quarters").val()) - parseFloat($("#user-opening-quarters").val())).toFixed(2));
    $("#variance-ones").val((parseFloat($("#user-closing-ones").val()) - parseFloat($("#user-opening-ones").val())).toFixed(2));
    $("#variance-fives").val((parseFloat($("#user-closing-fives").val()) - parseFloat($("#user-opening-fives").val())).toFixed(2));
    $("#variance-tens").val((parseFloat($("#user-closing-tens").val()) - parseFloat($("#user-opening-tens").val())).toFixed(2));
    $("#variance-twenties").val((parseFloat($("#user-closing-twenties").val()) - parseFloat($("#user-opening-twenties").val())).toFixed(2));
    $("#variance-fifties").val((parseFloat($("#user-closing-fifties").val()) - parseFloat($("#user-opening-fifties").val())).toFixed(2));
    $("#variance-hundreds").val((parseFloat($("#user-closing-hundreds").val()) - parseFloat($("#user-opening-hundreds").val())).toFixed(2));
    $("#variance-drawer-counts").val((parseFloat($("#user-closing-drawer").val()) - parseFloat($("#user-opening-drawer").val())).toFixed(2));

    $("#total-cash").val((
      parseFloat($("#user-closing-nickels").val())
      + parseFloat($("#user-closing-dimes").val())
      + parseFloat($("#user-closing-quarters").val())
      + parseFloat($("#user-closing-ones").val())
      + parseFloat($("#user-closing-fives").val())
      + parseFloat($("#user-closing-tens").val())
      + parseFloat($("#user-closing-twenties").val())
      + parseFloat($("#user-closing-fifties").val())
      + parseFloat($("#user-closing-hundreds").val())
      + parseFloat($("#user-closing-drawer").val())
    ).toFixed(2));

    $("#expected-cash-on-hand").val((
      parseFloat($("#user-opening-nickels").val())
      + parseFloat($("#user-opening-dimes").val())
      + parseFloat($("#user-opening-quarters").val())
      + parseFloat($("#user-opening-ones").val())
      + parseFloat($("#user-opening-fives").val())
      + parseFloat($("#user-opening-tens").val())
      + parseFloat($("#user-opening-twenties").val())
      + parseFloat($("#user-opening-fifties").val())
      + parseFloat($("#user-opening-hundreds").val())
      + parseFloat($("#user-opening-drawer").val())
      + parseFloat($("#user-change-order").val())
      + parseFloat($("#user-pos-eod-cash-deposit").val())
      - parseFloat($("#user-bank-deposit").val())
    ).toFixed(2));

    $("#over-short").val((
      parseFloat($("#total-cash").val())
      - parseFloat($("#expected-cash-on-hand").val())
    ).toFixed(2));
  };

  $('[id^=user-]').change(function() {
    updateValues();
  });

});

if (!Turbolinks) {
location.reload();
}

Turbolinks.dispatch("turbolinks:load");
